<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Прием  
    <span style="text-decoration:underline" v-if="depType!=undefined">{{ depType == 0 ? "Амбулаторного" : "Стационарного" }}</span>
    <span style="text-decoration:underline" v-else>{{ form.depType == 0 ? "Амбулаторного" : "Стационарного" }}</span>
    пациента
    </VaCardTitle>

    <VaCardContent style="text-align:left">
      <VaAlert
        v-if="form.medhistory!=null"
        color="primary"
        border="left"
        class="mb-6"
        >
        Этот осмотр будет прикреплен к истории болезни №{{ form.medhistory }}
      </VaAlert>
        <VaSkeletonGroup v-if="patientLoading">
          <VaSkeleton variant="text" class="va-h5" :lines=12 style="max-width:600px"/>
          <VaSkeleton variant="rounded" class="va-button mr-3 mb-2" height="2em" style="max-width:100px"/>
        </VaSkeletonGroup>

        <VaForm ref="formRef" v-show="!patientLoading" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-2 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
            <div class="va-table-responsive">
              <PatientProfile :patient="patient" v-if="patient.passport"/>
            </div>
          </VaCollapse>
          <VaAlert 
            v-if="lastvisit.id"
            border="left"
            color="primary"
            class="mt-2 mb-4"
          >
            <p>
              Пациент ранее посещал клинику, вы можете автоматически перенести информацию из последнего {{ lastvisit.depType == 0 ? "амбулаторного" : "стационарного" }} приема от {{ lastvisit.visitDate }} у врача: {{ lastvisit.doctor_full_name }}
            </p>
            <VaButton
                style="max-width:200px"
                class="mt-2 mb-2" 
                @click="fillVisit"
                preset="primary"
                icon="variable_insert"
              >Заполнить</VaButton>
          </VaAlert>
          
          <label v-if="tmpDepType!=1 && !visitId" class="va-input-label" style="color: var(--va-primary);">Прием</label>
          <VaRadio 
            v-if="tmpDepType!=1 && !visitId" 
            class="mb-3"
            v-model="form.examType"
            :rules="[
              (v) => v !== null || 'Укажите вид записи',
            ]"
            :options="[
              {
                text: 'Первичный',
                value: '0',
              },
              {
                text: 'Повторный',
                value: '1',
              },
            ]"
            value-by="value"
          />

          <label class="va-input-label" style="color: var(--va-primary);">Признак оплаты</label>
          <VaRadio 
            class="mb-3"
            v-model="form.paid"
            :options="[
              {
                text: 'ОМС',
                value: false,
              },
              {
                text: 'Платный',
                value: true,
              },
            ]"
            value-by="value"
          />
          
          <div class="rc-highlight" v-if="!visitId">
          <VaSelect
            background="#FFFFFF"
            v-model="template"
            :options="tmplObject"
            text-by="name"
            value-by="id"
            placeholder="Заполнить по шаблону"
          />
          </div>

          <VaTextarea class="mb-3"
            v-model="form.compliant"
            label="Жалобы"
            placeholder="На что жалуется пациент"
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea class="mb-3"
            v-model="form.anamnes_med"
            label="Анамнез болезни"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=20
          />

          <VaSwitch
            v-if="depType && depType==0"
            v-model="alStatus"
            label="Записать Анамнез жизни"
            size="small"
            class="mb-3"
          />
          <VaTextarea v-if="alStatus"
            class="mb-3"
            v-model="form.anamnes_life"
            label="Анамнез жизни"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=20
          />

          <VaTextarea class="mb-3"
            v-model="form.status_alergo"
            label="Аллергические реакции"
            placeholder="Известные аллергические реакции. Заполняется со слов пациента"
            autosize
            :minRows=5
            :max-rows=10
          />

          <VaTextarea class="mb-3"
            v-model="form.status_objective"
            label="Объективный статус"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />

          <VaTextarea class="mb-3"
            v-model="form.status_local"
            label="Локальный статус"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=20
          />

          <VaTextarea v-if="tmpDepType==0"
            class="mb-3"
            v-model="form.manipulation"
            label="Манипуляции"
            placeholder=""
            autosize
            :minRows=5
            :max-rows=10
          />

          <VaTextarea class="mb-3"
            v-model="form.clinimum"
            label="Клинический минимум"
            placeholder=""
            autosize
            :minRows=6
            :max-rows=10
          />

          <VaTextarea class="mb-3"
            v-model="form.exam_data"
            label="Данные обследований"
            placeholder=""
            autosize
            :minRows=6
            :max-rows=10
          />

          <VaTextarea v-if="tmpDepType==1"
            class="mb-1"
            v-model="form.proof"
            label="Обоснование диагноза"
            placeholder=""
            autosize
            :minRows=5
            :max-rows=10
          />
          <VaButton v-if="tmpDepType==1" class="mb-3" icon="arrow_upward" preset="primary" style="max-width:250px" @click="fillTheProof">Заполнить обоснование</VaButton>

          <VaInput class="mb-3"
            type="text"
            v-model="form.diagnosis"
            label="Диагноз основной"
            placeholder="Диагноз"
          />
          
          <VaInput v-show="tmpDepType==1"
            class="mb-3"
            type="text"
            v-model="form.diagnosis_secondary"
            label="Диагноз сопутствующий"
            placeholder="Диагноз сопутствующий"
          />
          
          <VaInput class="mb-3"
            type="text"
            v-model="form.mkb10"
            label="Код диагноза"
            placeholder="Код по классификации МКБ-10"
          />

          <VaTextarea v-if="tmpDepType==0"
            class="mb-4"
            v-model="form.recom"
            label="Рекомендации"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />
          
          <VaTextarea v-else
            class="mb-4"
            v-model="form.cure_plan"
            label="План ведения и лечения"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />
          <div class="rcflex">
            <VaButton 
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="$router.back()"
              preset="primary"
              color="secondary"
              icon="cancel"
            >
              {{ saved ? 'Вернуться' : 'Отменить'}}
            </VaButton> 
            <VaButton
              v-if="!visitId && !saved"
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="addVisit()"
              preset="primary"
              icon-right="system_update_alt"
            >
              Закончить прием 
            </VaButton>
            <VaButton
                :disabled="!saved"
                preset="primary"
                icon="print"
                class="mt-4 mr-2"
                @click.before="showVisitModal=!showVisitModal"
                @click="print('print',true)"
              >
                Печать
              </VaButton>
            <VaButton
              v-if="!visitId && !saved"
              style="max-width:max-content"
              class="mt-4 mr-2" 
              @click="addVisit(true)"
              preset="primary"
              icon-right="move_item"
            >
              Закончить прием и вернуться
            </VaButton>
            <VaButton
              v-if="visitId || saved"
              style="max-width:230px"
              class="mt-4 mr-2" 
              @click="patchVisit()"
              preset="primary"
              icon-right="save"
            >
              Сохранить изменения
            </VaButton>        
          </div>
        </VaForm>



        </VaCardContent>
  </VaCard>

  <VaModal
    :allowBodyScroll=true
    v-model="actionBlocked"
    size="small"
    blur
    cancelText="Назад к списку"
    :beforeCancel="back"
    :mobile-fullscreen=false
    :hideDefaultActions=true
    ref="blocked"
  >
    
    <template #default>
      <h4 class="va-h4">
        <VaIcon size="2rem" color="danger" name="close"/> Ошибка!
      </h4>
      <p class="mb-4">
        Сначала необходимо начать историю болезни.
      </p>
      <VaButton icon="arrow_back_ios_new" preset="primary" class="mr-2" @click.before="$refs.blocked.hide()" @click="$router.back()">
          Назад
      </VaButton>
      <VaButton icon="post_add" preset="primary"  @click.before="$refs.blocked.hide()" @click="$router.push('/medhistory/add/' + patientId)">
          Начать историю болезни
      </VaButton>
    </template>
  </VaModal>
  <VaModal
    :allowBodyScroll=true
    :closeButton="true"
    v-model="showVisitModal"
    max-height="85vh"
    hideDefaultActions
    fixed-layout
    :title="'Осмотр у врача'"
    ref="VisitDetailsModal"
  >
    <template #header>
      <VaButtonToggle
        v-model="printForm"
        preset="secondary"
        :options="formOptions"
        class="mb-4"
      />
    </template>

    <VisitPrintForm :visit="visit" id="print"/>

    <template #footer>
      <VaButton
        preset="primary"
        color="secondary"
        icon="close"
        class="mr-2"
        @click="$refs.VisitDetailsModal.hide()"
      >
        Закрыть
      </VaButton>
    </template>
  </VaModal>
</template>
<script setup>
import { formatDateTime } from '@/common/rcfx.js'
</script>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';
import { last } from "lodash";
import VisitPrintForm from "@/components/printforms/VisitPrintForm"


export default {
    components: { RouterLink, PatientProfile, VisitPrintForm },
    props: { 
      visitId: String,
      patientId: String,
      examType: String,
      depType: String,
      regId: String,
    },
    data() {
        return {
            tmpDepType: this.depType,
            medhistory: false,
            toggleCard: false,
            alStatus: this.depType && this.depType == 1 ? true : false,
            template: '',
            availTimeIntervals:{},
            mode: this.$route.name == 'visitEdit' ? 'edit' : 'add',
            tempdate: new Date(Date.now()),
            temptime:'',
            reg:{},
            lastvisit: {},
            form: {
              paid: false,
              compliant: '',
              anamnes_med: '',
              anamnes_life: !this.visitId ? 'Семейное положение:\nОбразование: \nГемотрансфузии: отрицает\nПеренесенные заболевания:\nДанные за КВИ:\nГепатит отрицает, туберкулез отрицает, венерические заболевания отрицает.\nНа диспансерном учете не состоит, отрицает.\nПеренесенные травмы: отрицает.\nПроведенные операции: отрицает.\nВредные привычки: отрицает.\nПрием препаратов: отрицает.' : '',
              status_alergo: '',
              status_objective: '',
              status_local: '',
              manipulation: '',
              clinimum: !this.visitId ? 'ФЛГ от __________ г.: норма.\nЯ/г от __________ г.: отрицательно\nRW от __________ г.: отрицательно.\nВИЧ от __________ г.: отрицательно.\nМаркеры гепатита от __________ г.: отрицательно.\nМазок на стафилококк от __________ г.: отрицательно.\nИФА на Ковид-19 от __________ г.: отрицательно.\n' : '',
              exam_data: '',
              proof: '',
              diagnosis: '',
              diagnosis_secondary: '',
              mkb10: '',
              recom: '',
              examType: this.examType ? this.examType : '',
              depType: this.depType ? this.depType : '',
              user: this.$store.state.auth.status.loggedIn ? this.$store.state.app.account.profile.id : '',
              patient: this.patientId ? this.patientId : '',
              medhistory: null
            },
            templates: [],
            tmplObject: {},
            patient: Object,
            doctorSchedule: Object,
            doctor_id: "",
            doctors: Object,
            addSuccess: false,
            addError: false,
            isValid:true,
            patientLoading: true,
            scheduleLoading:false,
            scheduleUpdated:0,
            actionBlocked: false,
            saveTimer: undefined,
            drafts: [],
            // draft_time: '',
            // draft_patient: '',
            saved: false,
            savedId: undefined,
            visit:{},
            showVisitModal: false,
          };
    },
    computed: {
      userprofile() {
        return this.$store.state.app.account.profile
      }
    },
    watch: {
      alStatus: function(value) {
        if (!this.form.anamnes_life.length>0) {
          if (value === true) {
            this.form.anamnes_life = 'Семейное положение:\nОбразование: \nГемотрансфузии: отрицает\nПеренесенные заболевания:\nДанные за КВИ:\nГепатит отрицает, туберкулез отрицает, венерические заболевания отрицает.\nНа диспансерном учете не состоит, отрицает.\nПеренесенные травмы: отрицает.\nПроведенные операции: отрицает.\nВредные привычки: отрицает.\nПрием препаратов: отрицает.'
          }
        }
      },
      medhistory: function(value) {
        if (this.depType==1 && (value == false || value == undefined)) {
          //console.log('fuck, medhistory not started!')
          this.actionBlocked = true
        }
      },
      tmpDepType: function(newValue) {
        console.log("Отделение сменилось на: " + newValue)
        this.form.depType = newValue
        this.tmplObject = this.templates.filter((record) => record.type == newValue);
        this.template = ''
      },
      template: function(newValue) {
        if (newValue != '') {
          console.log("Шаблон сменился на: " + this.template)
          let sample = this.tmplObject.find(record => record.id == newValue)
          this.form.status_local = sample.status_local
          this.form.cure_plan = sample.cure_plan
          this.form.recom = sample.recom
        }
      },
      doctor_id: function(newValue) {
        if (newValue) {
          console.log('doctor changed')
          this.getSchedule(this.formatDate(this.tempdate,'us'))
          this.form.user = newValue
        }
      },
    },
    methods: {
      async print(elid, wait=false) {
        if (wait) {
          await this.showVisitModal && this.$htmlToPaper(elid, null, () => {this.$refs.VisitDetailsModal.hide()});
        }else{
          await this.$htmlToPaper(elid);
        }
      },
      setVisited(reg_id) {
      axiosInstance
        .patch('/registrations/edit/'+ reg_id, {visited: 1})
        .then((response) => {
          console.log('Visited status setted')
        },
        (error) => {
          //console.log(JSON.stringify(error))
          console.log('Visited setting failed')
        })
    },
      fillTheTmplObject() {
        this.tmplObject = this.templates.filter((record) => record.type == this.depType)
      },
      fillTheProof() {
        this.form.proof = "Диагноз выставлен на основании жалоб, анамнеза болезни, объективных данных, данных лабораторно-инструментальных методов обследования:\n\n"
        + "Жалоб: " + this.form.compliant + "\n"
        + "Анамнеза болезни: " + this.form.anamnes_med + "\n"
        + "Локального статуса: " + this.form.status_local + "\n"
        + "Данных обследований:\n" + this.form.clinimum + "\n\n"+ this.form.exam_data
      },
      back(hide) {
        hide();
        this.$router.back()
      },
      proceed(hide) {
        hide();
        this.$router.push('/patients/' + this.newPatientData.id)
      },
      getPastVisit() {
        axiosInstance
        .get('/visits/?deptype=0&patient=' + this.patient + '&latest=true')
        .then(response => {
          this.pastvisit = response.data.results
        })
        .catch(error => {
          console.log('Visits: error ' + error);
        })
      },
      getVisit() {
        axiosInstance
          .get('/visit/'+this.visitId)
          .then(response => {
             this.checkAmbVisit()
            this.form = response.data.results[0]
            if (this.form.anamnes_life.length>0 || this.form.depType==1) this.alStatus = true
            this.tmpDepType = this.form.depType
            this.getPatient(this.form.patient)
            localStorage.setItem("draft", JSON.stringify(this.form))
          })
          .catch()
      },
      patchVisit() {
        var from = this.$route.query.from
        console.log('Trying to patch a Visit')
          if (this.form.depType == 0) {
            this.form.cure_plan = ""
          }else{
            this.form.recom = ""
            this.form.manipulation = ""
          }
        delete this.form.id
        delete this.form.doctor_full_name
        delete this.form.updated
        delete this.form.visitDate
        console.log('alstatus now is: ' + this.alStatus)
        if (!this.alStatus) this.form.anamnes_life = ""
          const visit = this.visitId ? this.visitId : this.savedId
        axiosInstance
            .patch('/visit/edit/'+visit, this.form)
            .then(response => {
              this.visit = response.data
              // const message = 'Patch ok' + JSON.stringify(response)
              this.deleteDraft(this.draft_index)
              //console.log(message);
              this.$vaToast.init({ message: 'Осмотр изменен', color: 'success', position: 'bottom-right' })
              from === 'medhistory' && this.$router.replace('/medhistory/' + response.data.medhistory)
              from === 'visitsmanage' && this.$router.replace('/visits/manage')
            })
                .catch(error => {
                  this.$vaToast.init({ message: 'Ошибка изменений', color: 'warning', position: 'bottom-right' })
                  console.log('Patch visit Request Failed: ' + JSON.stringify(error))
                  // this.addError = true;
            })

      },
      addVisit(back=false) {
        console.log('Trying to add a Visit')
        this.form.depType = this.tmpDepType
              if (this.form.depType == 0) {
                this.form.cure_plan = ""
              }else{
                this.form.recom = ""
                this.form.manipulation = ""
              }
        delete this.form.id
        delete this.form.registered
        delete this.form.updated
        !this.alStatus && delete this.form.anamnes_life

        axiosInstance
            .post('/visits/'+this.patientId, this.form)
            .then(response => {
              this.visit = response.data
              this.saved = true
              this.savedId = this.visit.id
              this.deleteDraft(this.draft_index)
              // const message = 'Added ok' + JSON.stringify(response)
              //console.log(message);
              switch(this.form.depType) {
                case "0":
                  this.addSuccess=true
                  this.setVisited(this.regId)
                  this.$vaToast.init({ message: 'Первичный осмотр в амбулатории сохранен', color: 'success', position: 'bottom-right'})
                  if (back) {
                    this.autosave("stop") 
                    this.$router.back()
                  }
                  break
                case "1":
                  this.$vaToast.init({ message: 'Первичный осмотр в стационаре сохранен', color: 'success', position: 'bottom-right'})
                  if (back) {
                    this.autosave("stop")
                    this.$router.push({name: 'editMedhistory', params: {medhistoryId: this.form.medhistory}})
                  }
                }
            })
                .catch(error => {
                  this.$vaToast.init({ message: 'Ошибка заполнения', color: 'warning', position: 'bottom-right'})
                  console.log('Addvisit Request Failed: ' + JSON.stringify(error))
                  // this.addError = true;
            })

      },
      getReg(redId) {
      axiosInstance
        .get('/registrations/'+redId+'/')
        .then((response) => {
            this.reg = response.data.results[0]
            this.form.compliant = this.reg.examReason
            this.form.paid = this.reg.paid
        })
        .catch(error => {
          console.log('Regs request failed')
        })
      },
      checkAmbVisit() {
        axiosInstance
        .get('/visits/?deptype=0&patient='+this.patientId+'&latest=true')
        .then(response => {
          let data = response.data
          console.log(data)
          this.lastvisit = data.count > 0 ? data.results[0] : {}
        })
        .catch(error => {
          console.log('Check amb visit: error ' + error);
        })
        .finally(() => {
          console.log('Check amb visit: Request completed')
        });
      },
      fillVisit() {
        this.form.compliant = this.lastvisit.compliant
        this.alStatus = this.lastvisit.anamnes_life.length > 0 ? true : false
        this.form.anamnes_life = this.lastvisit.anamnes_life
        this.form.anamnes_med = this.lastvisit.anamnes_med
        this.form.status_local = this.lastvisit.status_local
        this.form.manipulation = this.lastvisit.manipulation
        this.form.status_alergo = this.lastvisit.status_alergo
        this.form.diagnosis = this.lastvisit.diagnosis
        this.form.mkb10 = this.lastvisit.mkb10
        this.form.exam_data = this.lastvisit.exam_data
      },
      getTemplates() {
        axiosInstance
            .get(`templates/`)
            .then(response => {
                this.templates = response.data.results;
                this.tmplObject = this.templates.filter((record) => record.type == 1);
            })
            .catch(error => {
              console.log('Templates: error ' + error);
              this.tempaltes = []
        })
            .finally(() => {
              console.log('Templates: Request completed')
        });
      },
      getUsers() {
        axiosInstance
            .get(`doctors/`)
            .then(response => {
              this.doctors = response.data.results;
        })
            .catch(error => {
            console.log('Doctors Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('Doctors: Request successfully completed')
              this.isLoading = false
        });
      },
      getPatient(id) {
        this.patientLoading = true
        console.log('id is ' + id)
        axiosInstance
            .get(`patients/`+id+`/`)
            .then(response => {
              this.patient = response.data;
        })
            .catch(error => {
            console.log('Axios Request Failed: ' + error);
            this.gotError = true;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
              this.patientLoading = false
              if (!this.visitId) this.form.status_objective = 'Общее состояние удовлетворительное. Сознание ясное. Кожные покровы чистые, обычной окраски. Видимые слизистые оболочки чистые, обычной окраски. Периферические лимфатические узлы не увеличены, не пальпируются. Зев не гиперемирован. В легких дыхание везикулярное, проводится по всем полям, хрипов нет. Тоны сердца ясные, ритмичные. Артериальное давление ' + this.patient.artpressure + ' мм. рт. ст. пульс ' + this.patient.pulse + ' уд. в минуту. Живот мягкий, при пальпации безболезненный. Печень по краю реберной дуги, при пальпации безболезненная. Симптом поколачивания отрицателен с обеих сторон. Стул регулярный. Мочеиспускание свободное, безболезненное.'
              this.fillTheTmplObject()
        });
      },
      getMedhistory() {
        console.log('getting medhistory for patient')
        axiosInstance
            .get(`medhistory/patient/`+this.patientId)
            .then(response => {
              this.medhistory = response.data.results[0]
              this.form.paid = this.medhistory.paid
              this.form.medhistory = this.medhistory.id
              this.mode=="add" && this.checkAmbVisit()
        })
            .catch(error => {
              console.log('Axios Request Failed: ' + error);
              this.medhistory = false;
        })
            .finally(() => {
              console.log('One patient: Request successfully completed')
        });
      },
      autosave(action, interval) {
        if (action==="start") {
          console.log('starting autosaver')
          this.saveTimer = setInterval(() => {
            if (localStorage.getItem("drafts")) {
              console.log('found draft object')
              var draft_data = JSON.parse(localStorage.getItem("drafts"))
            }else{
              console.log('no draft object found')
              var draft_data = []
            }
            var foundId = draft_data.findIndex(item=>typeof(item) === "object" && item.patient_id === this.patient.id && item.owner === this.userprofile.id)
            console.log('find index result: ' + foundId)
            var draft_template = {
              patient_id: this.patient.id,
              patient_full_name: this.patient.full_name,
              time: new Date().getTime(),
              form: this.form,
              owner: this.userprofile.id,
            }
            console.log(draft_data)

            // Если черновик по пациенту уже есть изменяем существующий
            if (foundId >= 0) {
              console.log('editing draft record with id: ' + foundId)
              draft_data[foundId] = draft_template
            // Если нет создаем новый
            }else{
              console.log('creating new draft record...')
              draft_data.push(draft_template)
            }

            localStorage.setItem("drafts", JSON.stringify(draft_data))
            console.log('Draft autosaved..')
            // this.$vaToast.init({ message: 'Черновик сохранен..', color: '#ffffff60', position: 'bottom-right'})
          }, interval*1000)
        }else if (action==="stop") {
          console.log('stopping autosaver')
          clearInterval(this.saveTimer)
        }
      },
      async confirmFillFromDraft(draft_index) {
        this.$vaModal.confirm({
          size: "medium",
          message: 'У вас есть черновик приема пациента: ' + this.drafts[draft_index].patient_full_name +' от ' + new Date(this.drafts[draft_index].time).toLocaleDateString() + ' ' + new Date(this.drafts[draft_index].time).toLocaleTimeString() + '. Вы можете восстановить данные из черновика или удалить его и начать снова',
          title: 'Черновик',
          okText: "Восстановить",
          cancelText: "Удалить",
          allowBodyScroll: false,
        })
        .then((ok) => {
          if (ok) {
            console.log('Триггер на восстановление из черновика')
            this.fillFromDraft(draft_index)
          }else{
            console.log('Триггер на УДАЛЕНИЕ черновика')
            this.deleteDraft(draft_index)
            console.log('Триггер на СТАРТ записи нового черновика')
            this.autosave("start", 10)
          }
        })
      },
      fillFromDraft(draft_index) {
        // delete this.drafts[draft_index].visitDate, delete this.drafts[draft_index].user
        // // delete this.drafts.user
        // delete this.drafts.id
        // delete this.drafts.updated
        // delete this.drafts.patient
        // delete this.drafts.patient_full_name
        // delete this.drafts.patient_birthdate
        // delete this.drafts.patient_height
        // delete this.drafts.patient_weight
        // delete this.drafts.examType
        // delete this.drafts.depType
        // delete this.drafts.medhistory
        if (this.drafts[draft_index].form.anamnes_life.length > 0) {
          this.alStatus = true
        }
        this.form = Object.assign(this.form, this.drafts[draft_index].form)
        // this.form = {...this.drafts[draft_index]}
        if (this.medhistory != false) this.form.medhistory = this.medhistory.id
        this.form.examType = this.examType ? this.examType : '',
        this.form.depType = this.depType ? this.depType : '',
        this.form.user = this.$store.state.auth.status.loggedIn ? this.$store.state.app.account.profile.id : '',
        this.form.patient = this.patientId ? this.patientId : '',
        this.autosave("start", 10)
      },
      deleteDraft(draft_index) {
        console.log('deleting draft with index: ' + draft_index)
        this.drafts.splice(draft_index, 1)
        localStorage.setItem("drafts", JSON.stringify(this.drafts))
      },
      checkDraft() {
        if (localStorage.getItem("drafts") && !this.visitId) {
        console.log('Draft array found!')
        this.drafts = JSON.parse(localStorage.getItem("drafts"))
        this.draft_index = this.drafts.findIndex(item=>typeof(item) === "object" && item.patient_id === this.patientId && item.owner === this.userprofile.id)
        var draft_index = this.drafts.findIndex(item => item.patient_id == this.patientId && item.owner === this.userprofile.id)
        console.log('result: ' + draft_index)
        if (draft_index > -1) {
            this.confirmFillFromDraft(draft_index)
          }else{
            console.log('Draft for this patient(' + this.patientId + ') not found')
            this.autosave("start", 10)
          }
        }else{
          console.log('Draft array not found')
          this.autosave("start", 10)
        }
      },
    },
    mounted() {
      this.checkDraft()
      // if (localStorage.getItem("drafts") && !this.visitId) {
      //   console.log('Draft array found!')
      //   this.drafts = JSON.parse(localStorage.getItem("drafts"))
      //   var draft_index = this.drafts.findIndex(item=> item.patient_id === this.patient.id)
      //   if (draft_index > -1) {
      //     this.drafts_time = formatDateTime(new Date(Number(localStorage.getItem("time"))), "ru")
      //     this.drafts_patient = localStorage.getItem("draft_patient")
      //     delete this.drafts.visitDate
      //     delete this.drafts.user
      //     delete this.drafts.id
      //     delete this.drafts.updated
      //     delete this.drafts.patient
      //     delete this.drafts.patient_full_name
      //     delete this.drafts.patient_birthdate
      //     delete this.drafts.patient_height
      //     delete this.drafts.patient_weight
      //     delete this.drafts.examType
      //     delete this.drafts.depType
      //     delete this.drafts.medhistory
      //     this.confirmFillFromDraft()
      //   }
      // }else{
      //   this.autosave("start", 10)
      // }
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
      if (this.visitId) this.getVisit()
      if (this.depType==0 && this.regId) {
        this.getReg(this.regId)
        this.checkAmbVisit()
      }
     
      if (this.depType==1) {
        this.getMedhistory()
      }
      this.getTemplates()
      this.getUsers()
      this.getPatient(this.patientId)
      this.isLoading=false
    },
    beforeUnmount() {
      console.log('visits component was unmounted!')
      this.autosave("stop")
    }
}

</script>
<style>
</style>
  
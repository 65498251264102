<template>
  <VaCard stripe stripe-color="success">
    <VaCardTitle>
    Стационар
    </VaCardTitle>
    
    <VaCardContent>
      <div class="rcflex row rcflex-btw">
      <VaButtonToggle
        v-model="filter"
        preset="secondary"
        :options="filters"
        class="mb-2"
      />
      <VaButtonToggle
        v-model="myown"
        preset="secondary"
        :options="ownfilter"
        class="mb-2"
      />
      </div>
      <VaDataTable 
        clickable
        :items="filtered" 
        :columns="columns" 
        sticky-header
        noDataHtml="Здесь пока ничего нет"
        @row:click="gotoMH"
      >
      <template #cell(patient_full_name)="{ rowData }">
        {{ rowData.patient_full_name }}{{ rowData.patient_birthdate ? ', ' + rowData.patient_birthdate.split('-').reverse().join('.') : ''}}<br/>
        <VaChip size="small" :color="rowData.closed ? 'warning' : 'success'">{{ rowData.closed ? 'Закрыта '+rowData.closeDate : 'Открыта' }}</VaChip>
        <VaChip size="small" color="warning" class="ml-2" v-if="rowData.comments_count.length>0">Есть комментарии</VaChip>
        <VaChip size="small" color="secondary" class="ml-2" v-if="rowData.transfer">На передаче</VaChip>
        <VaChip size="small" color="#8f97cf" style="color:#ffffff" class="ml-2" v-if="!rowData.discharged">В стационаре с {{ rowData.openDate }}</VaChip>
        <VaChip size="small" color="success" class="ml-2" v-else>Выписан {{ rowData.discharge_date }}</VaChip>
      </template>
      <template #cell(doctor_full_name)="{ rowData }">
        {{ reduce(rowData.doctor_full_name) }}
      </template>
        <template #cell(actions)="{ row, rowData, isExpanded }">
          <VaButton v-show="false"
            :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
            preset="primary"
            class="w-full"
            @click="row.toggleRowDetails()"
          />
          <VaButton
            icon="quick_reference_all"
            preset="primary"
            class="w-full ml-2"
            @click="$router.push('/medhistory/' + rowData.id)"
          />
        </template>
        <template #cell(paid)="{ rowData }">
          <VaIcon
            :name="rowData.paid ? 'attach_money' : 'cruelty_free'"
            :color="rowData.paid ? 'success' : 'primary'"
            
            class="w-full ml-2"
            text="asd"
          />
           
        </template>

        <template #expandableRow="{ rowData }">
          <VaAlert
            color="primary"
            border="left"
          >
            <template #icon>
              <VaIcon name="info" />
            </template>
            <div class="rcflex column ml-2 mt-2">
              <div class="rcflex mb-2">
                <span>Оплата: {{ rowData.paid ? 'Платный' : "КСГ" }}</span>
              </div>
              <div class="rcflex mb-2">
                <span>Врач: {{ rowData.doctor_full_name }}</span>
              </div>
              <div class="rcflex mb-2">
                <span>Палата: {{ rowData.room }}</span>
              </div>
              <div class="rcflex mb-2">
                <span>Поступление: {{ rowData.openDate }}</span>
              </div>
              <div class="rcflex">
                <span>Выписка: {{ rowData.discharged ? rowData.discharge_date : "нет" }}</span>
              </div>
            </div>
          </VaAlert>
        </template>
      </VaDataTable>
    </VaCardContent>
  </VaCard>
</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';

export default {
    components: { RouterLink, PatientProfile },
    data() {
        return {
            filters: [
              { label: "Открытые", value: "open" },
              { label: "На проверке", value: "closed" },
              { label: "Архив", value: "accepted" },
            ],
            ownfilter: [
              { label: "Все", value: false },
              { label: "Только мои", value: true },
            ],
            columns: [
              { key: "id", label: "Номер", width: 1 },
              { key: "patient_full_name", label: "ФИО пациента", width: 10 },
              { key: "doctor_full_name", label: "Лечащий врач", width: 100 },
              { key: "room", label: "Палата", width: 10 },
              { key: "paid", label: "Оплата", width: 10 },
              { key: "actions", label: "Действия", width: 100 },
            ],
            medhistory: [],
            patient: {},
            filter: 'open',
            myown: false,
          };
    },
    computed: {
      filtered() {
        var iam = this.$store.state.app.account.profile.id
        if (this.filter === 'open') {
          return this.myown ? this.medhistory.filter(item => item.closed === false && item.accepted === false && item.user === iam) : this.medhistory.filter(item => item.closed === false)
        }
        if (this.filter === 'closed') {
          return this.myown ? this.medhistory.filter(item => item.closed === true && item.accepted === false && item.user === iam) : this.medhistory.filter(item => item.closed === true  && item.accepted === false)
        }
        if (this.filter === 'accepted') {
          return this.myown ? this.medhistory.filter(item =>item.accepted === true && item.user === iam) : this.medhistory.filter(item => item.accepted === true)
        }
      },
      ownfiltered() {
        if (this.filter === 'all') {
          return this.filtered
        }
        if (this.filter === 'myown') {
          return this.filtered.filter(item => item.user === this.$store.state.app.account.profile.id)
        }
      }
    },
    methods: {
      gotoMH(row) {
        this.$router.push('/medhistory/' + row.item.id)
      },
      reduce(string) {
        return string.split(' ')[0] + ' ' + string.split(' ')[1].substring(0,1) + '. ' + string.split(' ')[2].substring(0,1) + '.'
      },
      getMedhistoryList() {
        axiosInstance
            .get(`/medhistory/`)
            .then(response => {
                this.medhistory = response.data.results
            })
            .catch(error => {
              console.log('Templates: error ' + error);
        })
            .finally(() => {
              console.log('Templates: Request completed')
              //this.getPatient(this.medhistory.patient)
        });
      },
      getPatient(id) {
            axiosInstance
                .get(`patients/`+id+`/`)
                .then(response => {
                  this.patient = response.data;
            })
                .catch(error => {
                this.getPatientError = true;
            })
          },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getMedhistoryList()
    },
}

</script>
<style>
</style>
<template>
<VaCard stripe stripe-color="success">
    <VaCardTitle>
      <VaButton
      icon="arrow_back_ios_new"
      color="primary"
      icon-color="#333"
      class="mr-3"
      preset="secondary"
      hover-behavior="opacity"
      :hover-opacity="0.4"
      @click="$router.back()"
    />
    Выписной эпикриз пациента {{ patient.full_name }}
    </VaCardTitle>

    <VaCardContent style="text-align:left">
      <VaAlert
        v-if="form.medhistory!=null"
        color="primary"
        border="left"
        class="mb-6"
        >
        Это выписной эпикриз к истории болезни №{{ form.medhistory }}
      </VaAlert>
        <VaForm v-show="!filling" ref="dischargeForm" class="items-baseline gap-6" style="display: flex; flex-direction: column; max-width:100%">
          <VaButton preset="primary" @click="fillTheForm">Перезаполнить форму</VaButton>
          <label class="va-input-label" style="color: var(--va-primary);">Пациент</label>
          <VaCollapse
            icon="person"
            v-model="toggleCard"
            class="min-w-96 mb-3 rc-text-m"
            :header="patient.full_name + (patient.birthdate ? ', '+patient.birthdate : '')"
          >
            <div class="va-table-responsive" v-if="patient.id">
              <PatientProfile :patient="patient" />
            </div>
          </VaCollapse>

          <VaInput class="mb-3"
            type="text"
            v-model="form.clinic_days"
            label="Дней нетрудоспособности в году"
            placeholder="Указывается количество проведенных койко-дней"
          />
          
          <VaInput class="mb-3"
            type="text"
            v-model="form.work_incapacity_cert"
            label="Отметка о выдаче листка нетрудоспособности"
          />

          <VaTextarea class="mb-3"
            v-model="form.diagnosis"
            label="Диагноз основной"
            placeholder="Диагноз основной"
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea class="mb-3"
            v-model="form.diagnosis_secondary"
            label="Диагноз сопутствующий"
            placeholder="Диагноз сопутствующий"
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaTextarea class="mb-3"
            v-model="form.anamnes_med"
            label="Анамнез болезни"
            placeholder=""
            autosize
            :minRows=6
            :max-rows=20
          />

          <VaInput
            class="mb-3"
            type="text"
            v-model="form.diet"
            label="Диета"
          />

          <VaTextarea class="mb-3"
            v-model="form.treatment_type"
            label="Лечение"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=4
          />

          <VaTextarea class="mb-3"
            v-model="form.operation"
            label="Операция"
            placeholder=""
            autosize
            :minRows=2
            :max-rows=4
          />

          <VaTextarea class="mb-3"
            v-model="form.postoperation_note"
            label="Послеоперационный период"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=6
          />

          <VaInput
            class="mb-3"
            type="text"
            v-model="form.current_status"
            label="Состояние при выписке"
          />

          <VaTextarea class="mb-3"
            v-model="form.compliant"
            label="Жалобы"
            autosize
            :minRows=1
            :max-rows=2
          />

          <VaTextarea class="mb-3"
            v-model="form.status_objective"
            label="Объективный статус"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />

          <VaTextarea class="mb-3"
            v-model="form.status_local"
            label="Локальный статус"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />
          
          <VaTextarea class="mb-3"
            v-model="form.status_dressing"
            label="На перевязке"
            placeholder=""
            autosize
            :minRows=3
            :max-rows=5
          />

          <VaTextarea
            class="mb-4"
            v-model="form.recom"
            label="Рекомендации"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />

          <VaTextarea
            class="mb-4"
            v-model="form.exam_data"
            label="Данные исследований при поступлении"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />

          <VaTextarea
            class="mb-4"
            v-model="form.control_data"
            label="Конрольные исследования"
            placeholder=""
            autosize
            :minRows=10
            :max-rows=100
          />

          <div class="rcflex">
            <VaButton 
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="$router.back()"
              preset="primary"
              color="secondary"
              icon="cancel"
            >
              Отменить
            </VaButton> 
            <VaButton
              v-if="!dischargeId"
              style="max-width:200px"
              class="mt-4 mr-2" 
              @click="addDischargeEpicrisis()"
              preset="primary"
              icon-right="system_update_alt"
            >
              Сохранить
            </VaButton>
            <VaButton
              v-else
              style="max-width:230px"
              class="mt-4 mr-2" 
              @click="patchDischargeEpicrisis()"
              preset="primary"
              icon-right="save"
            >
              Сохранить изменения
            </VaButton>          
          </div>
        </VaForm>
      </VaCardContent>
  </VaCard>
</template>
<script setup>
  import { testTypes } from "@/common/locale"
</script>
<script>
import axiosInstance from "../services/api";
import PatientProfile from '@/components/PatientProfile';

export default {
    components: { PatientProfile },
    props: { 
      dischargeId: '',
    },
    data() {
      return {
        form: {
          clinic_days: '',
          work_incapacity_cert: '',
          diagnosis: '',
          diagnosis_secondary: '',
          anamnes_med: '',
          diet: '',
          treatment_type: '',
          operation: '',
          postoperation_note: '',
          current_status: '',
          compliant: '',
          status_objective: '',
          status_local: '',
          status_dressing: '',
          recom: '',
          exam_data: '',
          control_data: '',
          user: this.$store.state.app.account.profile.id,
          patient: '',
          medhistory: null
        },
      }
    },
    computed: {
      patient() {
        return this.$store.state.app.patient
      },
      medhistory() {
        return this.$store.state.app.medhistory
      },
      hosvisit() {
        return this.$store.state.app.hosvisit
      },
      preop() {
        return this.$store.state.app.preop
      },
      operation() {
        return this.$store.state.app.operation
      },
      diary() {
        return this.$store.state.app.diary
      },
      tests() {
        return this.$store.state.app.tests
      },
    },
    methods: {
      fillTheForm() {
        var test_results = ''
        console.log(this.tests)
        for (let test in this.tests) {
          if (this.tests[test].taken && this.tests[test].result!='') test_results += this.tests[test].result+'\n'
        }
        var intime = new Date(this.medhistory.openDate.split(' ')[0].split('.').reverse().join('-'))
        var outtime = new Date(this.medhistory.discharge_date.split(' ')[0].split('.').reverse().join('-'))
        var clinic_days = (outtime-intime)/3600000/24
        this.form.patient = this.patient.id
        this.form.medhistory = this.medhistory.id
        this.form.clinic_days = clinic_days + ' койко-дней'
        this.form.operation = this.operation.length ? this.operation.operationEnd.split(' ')[0] + ' ' + this.operation.operationName : 'нет'
        this.form.postoperation_note = this.operation.length ? '' : 'нет'
        this.form.diagnosis = this.operation.length ? this.operation.diagnosis_postop : this.hosvisit.diagnosis
        this.form.diagnosis_secondary = this.preop.length ? this.preop.diagnosis_secondary : 'нет'
        this.form.anamnes_med = this.hosvisit.anamnes_med
        this.form.status_objective = this.diary[0] ? this.diary[0].status_objective : this.hosvisit.status_objective
        this.form.status_local = this.diary[0] ? this.diary[0].status_local : this.hosvisit.status_local
        this.form.exam_data = this.hosvisit.clinimum + '\n\n' +this.hosvisit.exam_data 
        this.form.control_data = test_results
        this.form.work_incapacity_cert = 'не нуждается'
        this.form.current_status = 'удовлетворительное'
        this.form.compliant = 'активных нет'
        this.form.treatment_type = this.operation.length ? 'оперативное' : 'консервативное'
      },
      getDischargeEpicrisis() {
        axiosInstance
        .get('/discharge/'+this.dischargeId)
        .then((response) => {
          this.form = response.data
        })
        .catch((error) => {
          console.log('Get discharge epicrisis Failed: ' + JSON.stringify(error))
        })
      },
      patchDischargeEpicrisis() {
        delete this.form.id
        delete this.form.created
        delete this.form.updated
        axiosInstance
        .patch('/discharge/'+this.dischargeId, this.form)
        .then((response) => {
          const message = 'Patch ok' + JSON.stringify(response)
          this.$vaToast.init({ message: 'Выписной эпикриз изменен', color: 'success', position: 'bottom-right' })
          this.$router.replace('/medhistory/' + response.data.medhistory)
        })
        .catch((error) => {
          console.log('Patch discharge epicrisis Failed: ' + JSON.stringify(error))
          this.$vaToast.init({ message: 'Возникла ошибка, проверьте заполнение полей', color: 'warning', position: 'bottom-right' })
        })
      },
      addDischargeEpicrisis() {
        axiosInstance
        .post('/discharge/', this.form)
        .then(response => {
          this.$vaToast.init({ message: 'Выписной эпикриз сохранен', color: 'success', position: 'bottom-right', dangerouslyUseHtmlString: true, })
          this.$router.push({name: 'editMedhistory', params: {medhistoryId: this.form.medhistory}})
        })
        .catch(error => {
          console.log('Add discharge epicrisis Failed: ' + JSON.stringify(error))
          this.$vaToast.init({ message: 'Возникла ошибка, проверьте заполнение полей', color: 'warning', position: 'bottom-right' })
        })

      },
    },
    created() {
      if (!this.$store.state.app.patient.id) this.$router.back()
    },
    mounted() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
      if (this.dischargeId) {this.getDischargeEpicrisis()}
      else {this.fillTheForm()}
    },
}

</script>
<style>
</style>
  
<template>
  <VaCard stripe stripe-color="success">
    <VaCardTitle>
      Амбулатория
    </VaCardTitle>
    
    <VaCardContent>
      <div class="rcflex row vcenter">
      <VaButtonToggle
        v-model="myown"
        preset="secondary"
        :options="ownfilter"
        class="mb-2"
      />
      <VaChip size="small" v-if="query.length>2 && !loading" closeable v-model="asd">Фильтр: {{ query }}</VaChip>
      </div>
      <div class="rcflex row">
      <VaInput clearableIcon="true" background="#ffffff" v-model.trim="query" @keyup.esc="resetSearch" class="mb-2 md12" style="width:100%;" aria-autocomplete="off" placeholder="Поиск по пациенту или лечащему врачу">
        <template #prependInner>
          <VaIcon name="person_search" color="secondary"/>
        </template>
      </VaInput>
      </div>
      <VaAlert v-if="tooShortQuery"
            color="info"
            border="left"
            class="mb-4">
            Для поиска небходимо минимум 3 символа
      </VaAlert>
      <VaDataTable
        :loading="loading"
        :key="visits"
        clickable
        :items="visits" 
        :columns="columns" 
        sticky-header
        noDataHtml="Здесь пока ничего нет"
      >
      <template #cell(visitDate)="{ rowData }">
        <span v-if="rowData">{{ rowData.visitDate.split(' ')[0] }}</span>
      </template>

      <template #cell(patient_full_name)="{ rowData }">
          <VaIcon
            :name="rowData.paid ? 'attach_money' : 'cruelty_free'"
            :color="rowData.paid ? 'success' : 'primary'"
            class="w-full ml-2"
            text="asd"
          />
          {{ rowData.patient_full_name }}
          <br>
        Дата рождения {{ rowData.patient_birthdate ? rowData.patient_birthdate.split('-').reverse().join('.') : ''}}<br/>
        <VaChip v-if="rowData.medhistory" size="small" :color="rowData.medhistory ? 'warning' : 'success'">Осмотр прикперлен к ИБ №{{ rowData.medhistory }}</VaChip>
      </template>

      <template #cell(diagnosis)="{ rowData }">
        {{ rowData.diagnosis.length > 10 ? rowData.diagnosis.slice(0,10)+"..." : rowData.diagnosis }}
      </template>

      <template #cell(actions)="{ row, rowData, isExpanded, rowIndex }">
          <VaButton
            :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
            preset="primary"
            class="w-full"
            @click="row.toggleRowDetails()"
          />
          <VaButton
            icon="quick_reference_all"
            preset="primary"
            class="w-full ml-2"
            @click="selectedVisit=rowIndex;showVisitModal=!showVisitModal"
          />
          <VaButton
            :disabled="!!rowData.medhistory"
            icon="edit"
            preset="primary"
            class="w-full ml-2"
            @click="$router.push('/visits/edit/'+rowData.id+'?from=visitsmanage')"
          />
          <VaButton
            icon="print"
            preset="primary"
            class="w-full ml-2"
            @click="selectedVisit=rowIndex;showVisitModal=!showVisitModal;print('print', true)"
          />
        </template>
        <template #expandableRow="{ rowData }" class="asdasdasd">
          <VaAlert
            color="primary"
            border="left"
          >
            <template #icon>
              <VaIcon name="info" />
            </template>
            <b>Дата и время осмотра:</b> {{ rowData.visitDate }}<br>
                <b>Диагноз:</b> {{ rowData.diagnosis }}<br/>
                  <b>Врач:</b> {{ rowData.doctor_full_name }}
              <VaCard class="rc-comment-card" v-if="rowData.medhistory">
                <VaCardContent class="rcflex column">
                  <span class="mb-4">История болезни №{{ rowData.medhistory }}</span>
                  <span class="ml-4">Статус: пациент {{ rowData.medhistory_discharged==false ? 'выписан' : 'находится в стационаре, палата №'+rowData.medhistory_room }}</span>
                  <span class="ml-4">Поступление: {{ rowData.medhistory_openDate }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_discharged">Выписка:{{ rowData.medhistory_discharge_date }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_closed">История болезни закрыта: {{ rowData.medhistory_closeDate }}</span>
                  <span class="ml-4" v-if="rowData.medhistory_accepted">История болезни в архиве с: {{ rowData.medhistory_accepted_date }}</span>
                </VaCardContent>
              </VaCard>
            </VaAlert>
        </template>
      </VaDataTable>
      <VaPagination
        v-if="pages>1"
        :key="page"
        v-model="page"
        :pages="pages"
        :visible-pages="3"
        buttons-preset="primary"
        gapped
        class="mb-6 justify-center sm:justify-start"
      />
    </VaCardContent>
  </VaCard>

  <VaModal
    ::allowBodyScroll=true
    :closeButton="true"
    v-if="showVisitModal"
    v-model="showVisitModal"
    max-height="85vh"
    hideDefaultActions
    fixed-layout
    :title="'Амулаторный осмотр у врача ' + visits[selectedVisit].doctor_full_name"
    ref="VisitDetailsModal"
  >
    <template #header>
      <VaButtonToggle
        v-model="printForm"
        preset="secondary"
        :options="formOptions"
        class="mb-4"
      />
    </template>

    <VisitPrintForm :visit="visits[selectedVisit]" v-if="printForm" id="print"/>
    <VisitDetails :visit="visits[selectedVisit]" v-else/>

    <template #footer>
      <VaButton
        preset="primary"
        color="secondary"
        icon="close"
        class="mr-2"
        @click="showHosVisitModal=!showHosVisitModal;$refs.VisitDetailsModal.hide()"
      >
        Закрыть
      </VaButton>
      <VaButton
        :disabled="!printForm"
        preset="primary"
        icon="print"
        class="mr-2"
        @click.before="$refs.VisitDetailsModal.hide()"
        @click="print('print')"
      >
        Печать
      </VaButton>
      <VaButton
        :disabled="visits[selectedVisit].medhistory"
        preset="primary"
        color="primary"
        icon="edit"
        class="mr-2"
        @click.before="$refs.VisitDetailsModal.hide()"
        @click="$router.push('/visits/edit/'+visits[selectedVisit].id+'?from=visitsmanage')"
      >
        Изменить
      </VaButton>
    </template>
  </VaModal>
</template>
<script>
import axiosInstance from "../services/api";
import EventBus from "../common/EventBus";
import { RouterLink } from 'vue-router'
import PatientProfile from '@/components/PatientProfile';
import { VaCardContent, VaCardTitle, VaSwitch } from "vuestic-ui/web-components";
import VisitDetails from '@/components/VisitDetails.vue'
import VisitPrintForm from "@/components/printforms/VisitPrintForm.vue"
import debounce from 'lodash.debounce'

export default {
    components: { RouterLink, PatientProfile, VisitDetails,VisitPrintForm },
    data() {
        return {
            loading: false,
            ownfilter: [
              { label: "Все", value: false },
              { label: "Только мои", value: true },
            ],
            formOptions: [
              { label: "Табличная форма", value: false },
              { label: "Печатная форма", value: true },
            ],
            columns: [
              { key: "id", label: "Номер", width: 1 },
              { key: "visitDate", label: "Дата приема", width: 10 },
              { key: "patient_full_name", label: "ФИО пациента", width: 10 },
              { key: "doctor_full_name", label: "Лечащий врач", width: 100 },
              { key: "actions", label: "Действия", width: 100 },
            ],
            itemsPerPage: 20,
            pages:1,
            page:1,
            visits: [],
            selectedVisit: 0,
            patient: {},
            filter: 'open',
            myown: false,
            showVisitModal: false,
            printForm: true,
            printWindow: false,
            prev: String,
            next: String,
            query: '',
          };
    },
    computed: {
      ownfiltered() {
        if (this.filter === 'all') {
          return this.filtered
        }
        if (this.filter === 'myown') {
          return this.filtered.filter(item => item.user === this.$store.state.app.account.profile.id)
        }
      },
      tooShortQuery() {
        return this.query.length !== 0 && this.query.length<3 ? true : false 
      }
    },
    watch: {
      query: function (newValue) {
        if (!this.tooShortQuery) {
          this.debouncedSearch()
        }
      },
      myown() {
        this.getVisits(true,this.query)
      },
      page(value) {
        this.getVisits(false,this.query)
      }
    },
    methods: {
      async print(elid, wait=false) {
        if (wait) {
          await this.showVisitModal && this.$htmlToPaper(elid, null, () => {this.$refs.VisitDetailsModal.hide()});
        }else{
          await this.$htmlToPaper(elid);
        }
      },
      printDocument() {
        this.printWindow = !this.printWindow
      },
      resetSearch() {
        this.query=""
        this.getVisits(true,false)
      },
      debouncedSearch: debounce(function () {
              this.getVisits(true, this.query);
            }, 600),
      getVisits(resetPage, query=false) {
        this.loading = true
        console.log('firing request')
        var filter = ""
        if (query!=false && query.length > 2) filter += "&search=" + this.query
        if (this.myown===true) filter += "&user=" + this.$store.state.app.account.profile.id
        if (resetPage) this.page = 1
        filter += "&page=" + this.page
        axiosInstance
        .get('/visits/manage?deptype=0' + filter)
        .then(response => {
          var data = response.data
          this.pages = Math.ceil(data.count / this.itemsPerPage)
          this.visits = data.results
          this.loading = false
        })
        .catch(error => {
          console.log('get visits error')
          this.loading = false
        })
      },
    },
    created() {
      !this.$store.state.auth.status.loggedIn && this.$router.push('/login')
    },
    mounted() {
      this.getVisits(false,false)
    },
}

</script>
<style>
</style>